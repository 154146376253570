@use "node_modules/ag-grid-community/styles" as ag;
@include ag.grid-styles((
  themes: (
    compact: (extend-theme: quartz),
    compact-dark: (extend-theme: quartz-dark),
    quartz: (),
    quartz-dark: (),
  ),
));

//There does not seem to be any way to change the --ag-grid-size for just a single grid
// It has to apply to the whole theme. This means in order to not impact all the other grids across the app
// I needed to make a separate theme that extends the material theme and just changes the grid size - AJL 1/3/2024
.ag-theme-compact {
  --ag-grid-size: 5px;
}

.ag-theme-compact-dark {
  --ag-grid-size: 5px;
}

:root {
    --scenario-color: '#ffffff';
}

.historicalQuote .historical-column-group-header {
    justify-content: center;
}

.historicalQuote .ag-header-cell-label {
    justify-content: center;
    font-size: 0.75rem;
}

.historicalQuote .ag-header-active {
    background-color: var(--ag-subheader-background-color) !important;
}

.historicalQuote .ag-header-group-cell-label {
    justify-content: center;
}

.historicalQuote .ag-root-wrapper {
    max-height: 100% !important;
}

.historicalQuote .ag-header-cell {
    background-color: var(--ag-subheader-background-color);
    color: var(--ag-secondary-foreground-color);
    height: 2.25rem;
}

.historicalQuote .ag-header-row-column-group {
    background-color: var(--scenario-color);
    --ag-header-cell-hover-background-color: var(--scenario-color);
    --ag-header-cell-moving-background-color: var(--scenario-color);
    color: white;
}

.historicalQuote .cellCenter .ag-cell-wrapper {
    justify-content: center;
}

.historicalQuote .ag-root {
    max-height: 100% !important;
}