.color-picker-container {
    &.dark {
        .themed-color-value input {
            color: #ffffff;
        }

        .themed-color-value div::before {
            border-bottom-color: #ffffff;
        }

        .themed-icon path {
            color: #ffffff;
        }

        .themed-label {
            color: #ffffff;
        }
    }
}