
.units-grid .ag-header-cell-label {
  font-family: 'roboto';
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0;
}

.units-grid .ag-floating-bottom {
  overflow: hidden !important;
}

.units-grid .invalid-cell {
  border: 2px solid red !important;
}

.units-grid .ag-header-cell {
  padding-left: 12px;
  padding-right: 12px;
}

.units-grid .ag-right-aligned-header {
  text-align: right;
}

.units-grid .icon-cell {
  padding: 0;
  display: flex;
  align-items: center;
  border-right: solid 1px;
  border-right-color: var(--ag-border-color, #babfc7) !important;
}

.units-grid .outlined-cell {
  border-right: solid 1px var(--ag-border-color, #babfc7) !important;
  border-left: solid 1px var(--ag-border-color, #babfc7) !important;
}

.units-grid .borderless-cell {
  border: none !important;
}

.units-grid .icon-header {
  border-right: solid 1px;
  border-right-color: var(--ag-border-color, #babfc7) !important;
}

.units-grid .numeric-input {
  font-size: calc(var(--ag-font-size) + 1px);
  padding-left: calc(var(--ag-cell-horizontal-padding) - 1px);
  padding-right: 4px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: right;
  border: none;
}

/* Options cell editor pop-up background color, light mode */
.ag-theme-compact.units-grid .ag-popup-editor {
  background-color: #ffffff;
}

/* Options cell editor pop-up background color, dark mode */
.ag-theme-compact-dark.units-grid .ag-popup-editor {
  background-color: #1e2836;
}