.contact-info-grid .icon-cell {
  padding-left: 0;
  padding-right: 0;
  height: 30px;
}

.contact-info-grid .invalid-cell {
  border: 2px solid red !important;
}

.contact-info-grid .icon-cell {
  height: 100%;
}