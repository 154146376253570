.scenario-piece-card-accordion-summary:first-child>div {
    margin: 0;
}

.scenario-piece-card-box-shadow {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
}

.scenario-piece-card-accordion-details {
    padding-top: 0px !important;
    padding-right: 16px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
}