.application-scenario-grid .big-title {
  font-size: 14px;
  font-weight: 500;
}

.application-scenario-grid .check-column {
  padding: 0px 4px;
}

.application-scenario-grid .ag-row {
  font-size: 12px;
}
