.scenario-card {
  width: 340px;
  margin-bottom: 8px;
}

.scenario-card-box-shadow {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.scenario-accordion-summary {
  padding-right: 0px !important;
}

.price-yield-details-accordion-summary {
  padding-right: 0px !important;
}