.MuiButton-root + .MuiButton-root,
.MuiIconButton-root + .MuiButton-root,
.MuiButton-root + .MuiIconButton-root,
.MuiIconButton-root + .MuiIconButton-root {
  margin-left: 6px;
}

.MuiGrid-container + .MuiGrid-container {
  padding-top: 16px;
}

.ag-theme-quartz {
  --ag-subheader-background-color: #F2F2F2;
  font-family: 'Roboto', sans-serif;
}

.ag-theme-quartz-dark, 
.ag-theme-compact, 
.ag-theme-compact-dark{
  font-family: 'Roboto', sans-serif;
}

.recharts-cartesian-axis-tick{
  font-weight: 500;
}

.recharts-label-list {
  font-weight: 500;
  font-size: 0.875rem;
  
}

.scroll-container {
  flex: 1;
  min-height: 0;
  display: flex;
}

.scrollable-area {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
}